





































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";

import {
  GoalsCard,
  GroupsCard,
  VideosCard,
  NewsCard,
  PresentationsCard,
} from "./components";
import { createDashTour, Tour } from "@/core/utils/siteTour";

@Component({
  components: {
    PresentationsCard,
    GoalsCard,
    GroupsCard,
    VideosCard,
    NewsCard,
  },
})
export default class Dashboard extends Vue {
  @Getter("active") activeProfile!: boolean;

  @Getter("profile/showTutorial") showTutorial!: boolean;
  @Getter("profile/tutorialStep") tutorialStep!: number;

  @Getter("dashboard/loading") loading!: boolean;
  @Getter("dashboard/retrieved") retrieved!: boolean;
  @Action("dashboard/getData") getData!: () => Promise<any>;

  created() {
    if (!this.activeProfile) return this.$router.replace("/");
    if (!this.retrieved) this.getData();
  }

  tour: Tour | null = null;
  startTour() {
    this.tour = createDashTour();
    this.tour.start();
  }
  @Watch("$route", { immediate: true })
  routeChanged() {
    const { tour } = this.$route.query;
    if (tour === "true" && this.showTutorial && this.tutorialStep === 1)
      this.startTour();
  }
}
