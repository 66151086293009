





























































import Vue from "vue";
import { Condition } from "@/core/models";
import { Action, Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

import ConditionsSelect from "./ConditionsSelect.vue";
import { UsersSelect, NumberSelect } from "@/components/common";

@Component({ components: { UsersSelect, NumberSelect, ConditionsSelect } })
export default class AddGoalButton extends Vue {
  @Getter("groups/loadingGroups") loading!: boolean;
  @Getter("groups/usersManaged") users!: string[];
  @Getter("profile/getUserEmail") userEmail!: string;
  @Getter("groups/retrievedGroups") retrieved!: boolean;
  @Getter("goals/addGoalLoading") addGoalLoading!: boolean;

  @Action("goals/addGoal") addGoalAction!: (d: any) => Promise<void>;
  @Action("groups/getGroups") getGroups!: () => Promise<void>;

  showDialog = false;
  targetSessions = 5;
  conditions: Condition[] = [];
  assignOneself = false;
  selectedUsers: string[] = [];

  get canSubmit() {
    if (this.targetSessions <= 0) return false;
    if (this.selectedUsers.length == 0 && !this.assignOneself) return false;
    if (this.conditions.some(c => c.type === "" || c.value === ""))
      return false;
    return true;
  }

  toggleDialog() {
    if (!this.showDialog) this.$emit("opened");
    this.showDialog = !this.showDialog;
  }
  async addGoal() {
    if (this.addGoalLoading) return;

    if (this.assignOneself) this.selectedUsers.push(this.userEmail);
    let conds = this.conditions.map(c => {
      if (Array.isArray(c.value))
        return { type: c.type, value: c.value.join(";") };
      return c;
    });
    let data = {
      target: this.targetSessions,
      conditions: conds,
      assignedUsers: this.selectedUsers,
    };
    this.toggleDialog();
    await this.addGoalAction(data);
    this.$emit("added");
  }

  created() {
    if (!this.retrieved) this.getGroups();
  }
}
