import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"list-item",class:{ selected: _vm.isSelected },on:{"click":function($event){return _vm.$emit('select')}}},[_c(VOverlay,{attrs:{"absolute":"","value":_vm.deleting && _vm.deletedID == _vm.presentation.ID}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"32"}})],1),_c('div',{staticClass:"list-content"},[_c('div',{staticClass:"list-title"},[_c('span',[_vm._v(_vm._s(_vm.name))])]),_c('div',{staticClass:"list-subtitle"},[_c('span',[_vm._v(_vm._s(_vm.formattedSize)+" MB")])])]),_c('div',{staticClass:"list-actions"},[_c(VBtn,{staticClass:"mr-2 primary other--text",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openRecoder($event)}}},[_c(VIcon,[_vm._v("mdi-microphone")])],1),_c(VBtn,{staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.expandedDialog = true}}},[_c(VIcon,[_vm._v("mdi-arrow-expand-all")])],1),_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mr-2",attrs:{"icon":""}},on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,{attrs:{"dense":"","min-width":"200px"}},[_c(VListItem,{staticClass:"py-0",on:{"click":function($event){$event.stopPropagation();return _vm.download($event)}}},[_c(VListItemIcon,{staticClass:"mr-2"},[_c(VIcon,[_vm._v("mdi-download")])],1),_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t("pres.dl")))])],1),_c(VListItem,{staticClass:"py-0",on:{"click":function($event){$event.stopPropagation();return _vm.editOpen($event)}}},[_c(VListItemIcon,{staticClass:"mr-2"},[_c(VIcon,[_vm._v("mdi-pencil")])],1),_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t("pres.ed")))])],1),_c(VListItem,{staticClass:"py-0",on:{"click":function($event){$event.stopPropagation();return _vm.remove($event)}}},[_c(VListItemIcon,{staticClass:"mr-2"},[_c(VIcon,[_vm._v("mdi-delete")])],1),_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t("pres.rm")))])],1)],1)],1)],1)],1),_c('ExpandSlidesDialog',{attrs:{"presentation":_vm.presentation,"dialog":_vm.expandedDialog},on:{"update:dialog":function($event){_vm.expandedDialog=$event}}}),_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.edit($event)}},model:{value:(_vm.editForm),callback:function ($$v) {_vm.editForm=$$v},expression:"editForm"}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t("pres.edit")))]),_c(VCardText,[_c(VTextField,{attrs:{"rules":[function (s) { return !!s || _vm.$t('global.requiredf'); }]},model:{value:(_vm.newName),callback:function ($$v) {_vm.newName=$$v},expression:"newName"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","type":"submit","loading":_vm.editing,"disabled":_vm.editing}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.submit")))])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }