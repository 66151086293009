import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"elevation":"0","loading":_vm.loading,"disabled":_vm.loading}},[_c(VCardTitle,[_c('span',{staticClass:"headline font-weight-bold"},[_vm._v(_vm._s(_vm.$t("videos.videos")))]),_c(VSpacer),(!_vm.loading)?_c(VMenu,{attrs:{"offset-y":"","transition":"scroll-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"text":"","color":"accentText"}},on),[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v("mdi-account-group")]),_c('span',[_vm._v(_vm._s(_vm.selectedGroupName))]),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,false,2295221377)},[_c(VList,{attrs:{"dense":""}},[_c(VListItemGroup,{model:{value:(_vm.selectedGroupID),callback:function ($$v) {_vm.selectedGroupID=$$v},expression:"selectedGroupID"}},_vm._l((_vm.groupNames),function(g,i){return _c(VListItem,{key:i,attrs:{"value":g.id}},[_c('span',[_vm._v(_vm._s(g.name || _vm.$t("groups.all")))])])}),1)],1)],1):_vm._e()],1),(!_vm.loading)?_c(VCardText,{staticClass:"pt-0"},[_c(VRow,[_vm._l((_vm.pages.CurrentPage),function(video,i){return _c(VCol,{key:i,attrs:{"cols":"12","md":12 / _vm.limit}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"pa-2",class:{ lightgray2: hover },attrs:{"hover":"","elevation":"0"}},[_c('VideoPlayer',{key:video.File.URI,attrs:{"id":i,"url":video.File.URI}}),_c('div',{staticClass:"pt-4 headline font-weight-bold text-truncate main--text"},[(_vm.getIsPinned(video.GroupID, video.ID))?_c('span',{staticClass:"mr-2"},[_c(VIcon,[_vm._v("mdi-pin")])],1):_vm._e(),_c('span',[_vm._v(_vm._s(video.Title))])]),_c('div',{staticClass:"subtitle-1 main--text text-truncate"},[_vm._v(" "+_vm._s(video.Description)+" ")]),(_vm.isAll)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("dashboardPage.videosCard.group"))+": "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getGroupName(video.GroupID))+" ")])]):_vm._e()],1)]}}],null,true)})],1)}),(_vm.pages.CurrentPage.length === 0)?_c('div',{staticClass:"mt-4"},[_c('span',[_vm._v(_vm._s(_vm.$t("videos.none")))])]):_vm._e()],2),_c('PagesRender',{attrs:{"pages":_vm.pages}})],1):_c(VCardText,[(_vm.loading)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("dashboardPage.videosCard.loadingVids"))+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t("dashboardPage.videosCard.noGroups")))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }