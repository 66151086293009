


















































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Presentation } from "@/core/models/";
import { Component, Prop } from "vue-property-decorator";
import ExpandSlidesDialog from "./ExpandSlidesDialog.vue";

@Component({ components: { ExpandSlidesDialog } })
export default class SinglePresentation extends Vue {
  @Prop({ default: () => false }) isSelected!: boolean;
  @Prop({ default: () => null }) presentation!: Presentation;
  @Getter("presentations/getDeleteLoading") deleting!: boolean;
  @Getter("presentations/getDeletedPresentationID") deletedID!: number;
  @Action("presentations/deletePresentation") deleteAction!: Function;
  @Action("presentations/edit") editAction!: (data: any) => Promise<void>;

  newName = "";
  editForm = true;
  editing = false;

  editDialog = false;
  expandedDialog = false;

  openRecoder() {
    this.$router.push(`/recorder?presentation=${this.presentation.ID}`);
  }

  get name() {
    if (this.presentation && this.presentation.Name)
      return this.presentation.Name;
    else if (this.presentation.File) return this.presentation.File.Name;
    else return "";
  }
  get formattedSize() {
    if (!this.presentation.File) return 0;
    const size = this.presentation.File.Size;
    return Math.round((size / Math.pow(2, 20)) * 100) / 100;
  }

  download() {
    window.open(this.presentation.File.URI, "_blank");
  }
  remove() {
    this.deleteAction(this.presentation.ID);
    this.$emit("remove");
  }
  editOpen() {
    this.editDialog = true;
    this.editForm = true;
    this.newName = this.name;
  }
  async edit() {
    if (!this.editForm) return;
    this.editing = true;
    const data = {
      id: this.presentation.ID,
      name: this.newName,
    };
    await this.editAction(data);
    this.editing = false;
    this.editForm = true;
    this.editDialog = false;
  }
}
